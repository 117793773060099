<template>
  <div class="login-page">
    <div class="login-page-header">
      <img src="../assets/logo.svg">
      <span>{{ globalConfig.title }}</span>
<!--      <div style="padding-right: 30px">-->
<!--        <div style="margin-bottom: 5px">-->
<!--          <el-link type="primary" icon="el-icon-view" @click.native="openNotice">-->
<!--            水利部水利安全生产风险管控“六项机制” 网络答题活动的通知-->
<!--          </el-link>-->
<!--        </div>-->
<!--        <div>-->
<!--          <el-link type="primary" icon="el-icon-view" @click.native="openDoc">-->
<!--            安全生产法知识网络答题活动-->
<!--          </el-link>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="login-page-body">
      <div class="login-page-body-left"></div>
      <div class="login-page-body-right">
        <login-form @login="onLogin"></login-form>
      </div>
    </div>
    <div class="company">
      <div>版权归属：杭州合行科技有限公司</div>
    </div>
  </div>
</template>

<script>

import BaseVue from "@/components/base/BaseVue";
import CodeSelect from "@/components/base/CodeSelect";
import OrgSelect from "@/components/base/OrgSelect";
import AreaCascader from "@/components/base/AreaCascader";
import OrgInput from "@/components/base/OrgInput";
import LoginForm from "@/components/LoginForm";

export default {
  extends: BaseVue,
  components: { LoginForm, OrgInput, AreaCascader, OrgSelect, CodeSelect},

  data() {
    return {
    }
  },

  methods: {
    onLogin() {
      this.$router.push("/");
    },
    openNotice() {
      window.open("http://sljd.mwr.gov.cn/tzgg/202205/t20220519_1573765.html");
    },
    openDoc() {
      window.open("/notice.html")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.login-page {
  width: 100vw;
  min-width: 1024px;
  height: 100vh;
  background: #EFF2F6 url(../assets/loginBG.png) no-repeat;
  background-size: 100% 100%;

  &-header {
    width: 100vw;
    height: 80px;
    background: #fff;
    display: flex;
    flex-shrink: 1;
    align-items: center;
    img {
      height: 55px;
      margin-left: 8%;
    }

    span {
      color: #004080;
      font-size: 32px;
      font-weight: bold;
      padding: 0px 20px;
      flex-grow: 1;
    }
  }

  &-body {
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;

    &-left {
      width: 480px;
      height: 480px;
      background: url('../assets/loginMiniBG.png') no-repeat;
      background-size: 100% 100%;
    }

    &-right {
      margin-left: 120px;
    }
  }
  .company{
    color: #ffffff;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 20px;
    text-align: center;
    line-height: 24px;
    .el-link+.el-link{
      margin-left: 30px
    }
  }
}
</style>
