<template>
  <el-autocomplete v-bind="$attrs" v-on="$listeners"
                   value-key="orgName"
                   :fetch-suggestions="search"
                   clearable
                   style="width: 100%"
  >
  </el-autocomplete>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  extends: BaseVue,
  data() {
    return {
    }
  },
  methods: {
    search(querystring, callback) {
      if(!querystring) {
        callback([]);
        return;
      }
      this.postForm("/org/search", {
        orgName: querystring
      }, true).then(dr => {
        callback(dr.rows);
      });
    }
  }
}
</script>
