<template>
  <el-select ref="orgSelect" v-bind="$attrs" v-on="$listeners" remote :remote-method="query"
             :loading="loading" filterable clearable default-first-option autofocus
             style="width: 100%">
    <el-option v-for="item in items" v-bind:key="item.orgId" :value="item.orgId" :label="item.orgName">
    </el-option>
  </el-select>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  extends: BaseVue,
  props: {
    orgType: {
      require: false
    }
  },
  data() {
    return {
      items: []
    }
  },
  created() {
    if(!this.remote) {
      this.loadData("");
    }
  },
  methods: {
    query(str) {
      if(!str) {
        return;
      }
      this.loadData(str);
    },
    loadData(str){
      this.loading = true;
      this.postForm("/org/search", {
        orgType: this.orgType || '',
        orgName: str
      }, true).then(dr => {
        this.items = dr.rows;
      }).finally(()=>{
        this.loading = false;
      });
    },
    //初始化显示
    init(org) {
      this.$refs.orgSelect.cachedOptions.push({
        currentLabel: org.orgName,
        currentValue: org.orgId,
        label: org.orgName,
        value: org.orgId
      })
    }
  },
  watch: {
    orgType(newValue,oldValue) {
      this.loadData();
    }
  }
}
</script>
