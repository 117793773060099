<template>
  <el-cascader v-bind="$attrs" v-on="$listeners" :props="config" clearable style="width: 100%"></el-cascader>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";

export default {
  extends: BaseVue,
  props: {
    codeType: {
      default: ''
    },
    props: {
      require: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      config: Object.assign({
        lazy: true,
        value: 'codeValue',
        label: 'codeText',
        lazyLoad:  (node, resolve) => {
          this.getCodes(this.codeType, node.root ? null : node.data.codeValue ).then(dr => {
            let items = dr.rows;
            items.forEach(item => {
              //区县是3级
              item.leaf = item.ext1 == '3';
            });
            resolve(items);
          })
        }
      }, this.props || {})
    }
  },
  created() {
    // this.getCodes(this.codeType, this.parentCode).then((dr) => {
    //   this.items = dr.rows || []
    // });
  }
}
</script>
