<template>
  <div class="login-form">
    <div class="login-title">
      <i class="el-icon-unlock"></i>
      登录
    </div>
    <el-form ref="loginForm" :model="loginForm" label-width="0">
      <el-form-item prop="mobile">
        <el-input v-model.number="loginForm.mobile" size="large"
                  maxlength="11"
                  placeholder="请输入手机号">
          <template slot="prepend"><i class="el-icon-user"></i>手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="pwd">
        <el-input type="password" v-model="loginForm.pwd" size="large"
                  maxlength="50"
                  placeholder="请输入密码">
          <template slot="prepend"><i class="el-icon-lock"></i>密　码</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifyCode">
        <div class="random-code-item">
          <div>
            <el-input type="text" v-model="loginForm.verifyCode" size="large"
                      :minlength="1" :maxlength="6"
                      placeholder="请输入验证码" @keypress.native="enterPress">
              <template slot="prepend"><i class="el-icon-warning-outline"></i>验证码</template>
            </el-input>
          </div>
          <el-tooltip content="点击更换验证码" effect="light">
            <el-image fit="fill"
                      :src="globalConfig.apiRoot+'/login/random-code-image?_t='+time"
                      @click="time = new Date().getTime()">
              <span slot="placeholder">loading...</span>
            </el-image>
          </el-tooltip>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" style="width: 100%" :loading="loading" @click="login">登 录</el-button>
    <div class="text-buttons">
<!--      <el-button type="text" size="large" @click="register">用户注册</el-button>-->
      <el-button type="text" size="large" @click="forgetPassword">忘记密码？</el-button>
    </div>
  </div>
</template>

<script>

import BaseVue from "@/components/base/BaseVue";
import CodeSelect from "@/components/base/CodeSelect";
import OrgSelect from "@/components/base/OrgSelect";
import AreaCascader from "@/components/base/AreaCascader";
import OrgInput from "@/components/base/OrgInput";
import {validatorEnum} from "@/enums";

export default {
  extends: BaseVue,
  components: {OrgInput, AreaCascader, OrgSelect, CodeSelect},

  data() {
    return {
      time: new Date().getTime(),
      loginForm: {
        mobile: '',
        pwd: '',
        verifyCode: ''
      }
    }
  },
  methods: {
    login() {
      const form = this.$refs["loginForm"];
      form.validate((success)=>{
        if(!success) {
          return;
        }
        this.loading = true
        this.postForm("/login", {
            mobile: this.loginForm.mobile,
          pwd: this.encryptSm3(this.loginForm.pwd),
          verifyCode: this.loginForm.verifyCode
        }).then((dr)=> {
          this.$emit("login");
        }).catch((dr)=>{
          this.$message.error(dr.msg);
          if(dr.code != 403) {
            this.loginForm.mobile = "";
            this.loginForm.pwd = "";
          }
          this.loginForm.verifyCode = "";
          this.time = new Date().getTime();
        }).finally(()=>{
          this.loading = false
        });
      });
    },
    enterPress(e) {
      if(e.key == "Enter") {
        this.login();
      }
    },
    register() {
      this.$router.push({
        path: "/register",
        query: this.$route.query || {}
      });
    },
    forgetPassword() {
      this.$router.push({
        path: "/resetPassword",
        query: this.$route.query || {}
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";
.login-form{
  width: 377px;
  height: 334px;
  margin: auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 17px 36px;
  box-sizing: border-box;
  z-index: 99;
  position: relative;
  text-align: left;

  .login-title {
    font-size: 20px;
    color: #004080;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .random-code-item {
    display: flex;
    justify-content: left;
    align-items: center;

    .el-image {
      width: 90px;
      height: 40px;
      margin-left: 5px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      cursor: pointer;
    }
  }
  .text-buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
